html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  background-color: #003300;
}

.section {
  min-height: 100vh;
  width: 100%;
  position: relative;
}

.section:last-child {
  min-height: 50vh;
  width: 100%;
}

.hero {
  background-color: #252422;
  background-image: linear-gradient(to bottom, transparent, #252422),
    url("../images/header.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}

.mainnav {
  list-style-type: none;
  padding: 0;
  text-align: center;
  display: inline-block;
  color: white;
  font-weight: bolder;
  text-decoration: none;
  font-size: 1.2em;
  font-family: Arial, Helvetica, sans-serif;
  padding-right: 50px;
}

.mainnav:hover {
  text-shadow: 2px 2px 4px black, 0 0 50px white, 0 0 10px whitesmoke;
  color: #c0c0ae;
}

.mainnav1 {
position: absolute;
right: 0;
}

#hero-text {
  float: right;
  padding-right: 8%;
}

.hero h1 {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 7em;
  text-align: center;
  text-decoration: underline;
  margin-bottom: 0;
}

.hero h2 {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.4em;
  float: right;
  padding-right: 8%;
  margin-top: -1%;
}

.content {
  position: absolute;
  right: 8%;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  width: 30%;
  top: 300px;
  background: rgba(114, 110, 110, 0.5);
  border-radius: 25px;
}

.content h3 {
  width: 80%;
  margin-left: 10%;
  margin-bottom: 0;
}

.content p {
  margin-top: 3px;
  width: 80%;
  margin-left: 10%;
}

.content p a {
  color: white;
}

.content p a:hover {
  color: #e0ebeb;
}

.about {
  background-image: linear-gradient(#252422, #403d39);
  min-height: 50vh;
  width: 100%;
}

.about:after {
    content: "";
    position: absolute;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    top: -25px;
    border-bottom: 3px solid white;
  }

.services {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  div {
    height: 300px;
    width: 31%;
    flex: 1 0 auto;
    border-radius: 20px;
    box-sizing: border-box;
    margin-right: 1%;
    margin-bottom: 1%;
  } 
  &:first-of-type {
    margin-left: 0.5%;
  }
}

.tile {
  position: relative;
  h2 {
      position: absolute;
      margin: 0;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      text-shadow: 0 1px 0 black, 0 1px 0 black, 0 2px 0 black;
      color: white;
      text-align: center;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 900;
      font-size: 2.5vw;
    }
    p {
      opacity: 0;
      font-size: 1em;
      font-weight: normal;
      color: white;
      font-family: Arial, Helvetica, sans-serif;
      text-align: center;
      position: absolute;
      margin: 0;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
    } 
    &:hover{
      p {
        opacity: 1;
      }
    }
    ul {
      opacity: 0;
      font-size: 1em;
      font-weight: normal;
      display: block;
      color: white;
      font-family: Arial, Helvetica, sans-serif;
      left: 0;
      right: 0;
      top: 125px;
      bottom: 0;
      text-align: center;
    } 
    &:hover {
      ul {
        opacity: 1;
      }
  }
}

#artificial {
  background-image: url("../images/artificial.jpg");
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    background-image: url("../images/boxfade.png");
    transition: background-image .5s ease;
    h2 {
      opacity: 0;
      transition: all .5s ease;
    }
  }
}

#natural {
  background-image: url("../images/natural.jpg");
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    background-image: url("../images/boxfade.png");
    transition: background-image .5s ease;
    h2 {
      opacity: 0;
      transition: all .5s ease;
    }
  } 
}

#coring {
  background-image: url("../images/corer.jpg");
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    background-image: url("../images/boxfade.png");
    transition: background-image .5s ease;
    h2 {
      opacity: 0;
      transition: all .5s ease;
    }
  } 
}

#scarify {
  background-image: url("../images/scarify.jpg");
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    background-image: url("../images/boxfade.png");
    transition: background-image .5s ease;
    h2 {
      opacity: 0;
      transition: all .5s ease;
    }
  } 
}

#fertilise {
  background-image: url("../images/fertilise.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    background-image: url("../images/boxfade.png");
    transition: background-image .5s ease;
    h2 {
      opacity: 0;
      transition: all .5s ease;
    }
  } 
}

#reseed {
  background-image: url("../images/reseed.JPG");
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    background-image: url("../images/boxfade.png");
    transition: background-image .5s ease;
    h2 {
      opacity: 0;
      transition: all .5s ease;
    }
  } 
}

#rotary {
  background-image: url("../images/rotary.jpg");
  background-repeat: no-repeat;
  background-position: center; 
  &:hover {
    background-image: url("../images/boxfade.png");
    transition: background-image .5s ease;
    h2 {
      opacity: 0;
      transition: all .5s ease;
    }
  }
}

#cutting {
  background-image: url("../images/cutter.jpg");
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    background-image: url("../images/boxfade.png");
    transition: background-image .5s ease;
    h2 {
      opacity: 0;
      transition: all .5s ease;
    }
  } 
}

#topdress {
  background-image: url("../images/topdress.jpg");
  background-repeat: no-repeat;
  background-position: center; 
  &:hover {
    background-image: url("../images/boxfade.png");
    transition: background-image .5s ease;
    h2 {
      opacity: 0;
      transition: all .5s ease;
    }
  }
}

.contact {
  background-image: linear-gradient(#403d39, #4d5359);
  position: relative;
  padding-top: 5%;
  outline: 0;
}

.contact:after {
  content: "";
  position: absolute;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
  border-bottom: 3px solid white;
}

.contact h1 {
  text-align: center;
  margin-top: 0px;
  font-weight: lighter;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
}

.contact p {
  text-align: center;
  margin-top: 50px;
  font-weight: lighter;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
}

.contact ul {
  list-style-type: none;
  padding: 0;
}

.contact ul li {
  display: block;
  text-align: center;
}

.footer-link {
  color: white;
  font-weight: lighter;
  text-decoration: none;
  font-size: .8em;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  display: inline-block;
  margin-bottom: 15px;
  transition: all 150ms;
}

.contact a:hover {
  color: #e0ebeb;
  transition: all 150ms;
}

.icons {
  color: white;
  font-weight: lighter;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  margin: 0 auto;
}

.icons a {
  color: white;
  font-weight: lighter;
  text-decoration: none;
  font-size: 1.2em;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  margin: 0 auto;
  letter-spacing: 10px;
  transition: all 150ms;
}

.footerimg img {
  width: 100%;
  height: auto;
  margin-top: 0px;
}

@media only screen and (max-width: 1046px) {
  .footerimg img {
max-width: auto;
max-height: auto;
  }
  .content {
    width: 50%;
  }
}

@media only screen and (max-width: 918px) {
  .hero h1 {
    font-size: 5em;
  }
  .hero h2 {
    font-size: 1.2em;
  }
  .mainnav {
    font-size: 1em;
  }

  .content {
    width: 50%;
    top: 300px;
  }
  .footerimg img {
        max-height: 200px;
      }
}

@media only screen and (max-width: 694px) {
  .content {
    width: 84%;
  }
  .hero {
    min-height: 70vh;
    width: 100%;
  }
  .services div {
    width: 48%;
  }
  .tile h2 {
    font-size: 2.5em;
  }
  .about:after {
    display: none;
  }

  .mainnav {
    padding-right: 20px;
    font-size: 1em;
    right: 0;
    left: 0;
  }

  .mainnav1 {
    right: 0;
    left: 0;
  }
}

@media only screen and (max-width: 450px) {
  .hero {
    min-height: 100vh;
  }

  #hero-text {
    padding-right: 0;
  }

  .hero h1 {
    font-size: 5em;
  }

  .services div {
    padding-top: 5px;
    width: 100%;
  }

  .mainnav {
    display: none;
  }
}